// TermsAndConditions.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { PROJECTNAME } from '../../utils/constants';

const TermsAndConditions: React.FC = () => {
    const date= (new Date("12-08-2024")).toString();
  return (
    <div className="page-container">
      <Helmet>
  <title>Terms and Conditions - Course Echo</title>
  <meta name="description" content="Review the terms and conditions governing your use of our service. By using our website, you agree to these terms." />
  <meta name="keywords" content="terms and conditions, user agreement, service terms, Course Echo" />
  <meta name="author" content={PROJECTNAME} />

  {/* Open Graph (OG) meta tags */}
  <meta property="og:title" content="Terms and Conditions - Course Echo" />
  <meta property="og:description" content="Review the terms and conditions governing your use of our service. By using our website, you agree to these terms." />
  <meta property="og:image" content="https://courseecho.com/terms-conditions-image.png" /> {/* Replace with actual image URL for the Terms and Conditions page */}
  <meta property="og:url" content="https://courseecho.com/terms-and-conditions" /> {/* Replace with the specific page URL */}
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CourseEcho" />

  {/* Twitter Card meta tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Terms and Conditions - Course Echo" />
  <meta name="twitter:description" content="Review the terms and conditions governing your use of our service. By using our website, you agree to these terms." />
  <meta name="twitter:image" content="https://courseecho.com/terms-conditions-image.png" /> {/* Replace with actual image URL for the Terms and Conditions page */}
  <meta name="twitter:creator" content="@CourseEcho" /> {/* Replace with your Twitter handle */}
</Helmet>

      <h1>Terms and Conditions</h1>
      <p>Last updated: {date}</p>
      <p>These terms and conditions govern your use of our service. By using our website, you agree to these terms.</p>
      <Link to="/">Back to Home</Link>
    </div>
  );
};

export default TermsAndConditions;
