import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import CourseCard from '../components/CourseCard';
import { Course } from '../data/coursesData';
import { getCourseByCategoryDisplayId, getCourses } from '../services/CourseService';
import './Courses.css';
import { Helmet } from 'react-helmet-async';

interface CoursesProps {
  courses: Course[];
  isImported:boolean;
}

const Courses: React.FC<CoursesProps> = ({isImported}) => {
  const [loading, setLoading] = useState(true); // State to handle loading

  const [cartItems, setCartItems] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const categoryDisplayId = searchParams.get('course-category');
  let title ='Courses - Course Echo | Learn Programming and Development'
  if(categoryDisplayId)
  {
    title= `Courses in Category ${categoryDisplayId} - Course Echo | Learn ${categoryDisplayId} Courses`;
  }

  // State for search term, sorting option, and pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [courses, setCourses] = useState<Course[]>([]);
  // Fetch courses when the component mounts
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const courseData = categoryDisplayId
          ? await getCourseByCategoryDisplayId(categoryDisplayId || '')
          : await getCourses();
        setCourses(courseData); // Ensure resolved data is passed here
        setLoading(false)
      } catch (error) {
        console.error('Failed to load courses:', error);
        setLoading(false)
      }
    };
    fetchCourses();
  }, [categoryDisplayId]); // Add dependency on categoryDisplayId
  

  // Handle adding items to the cart
  const handleAddToCart = () => {
    setCartItems(cartItems + 1);
  };

  // Function to handle search input
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle sort change
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortOption(event.target.value as 'asc' | 'desc');
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Function to clear the filters (search and sort)
  const clearFilters = () => {
    setSearchTerm('');
    setSortOption('asc');
    setCurrentPage(1);
  };

  // Filter courses based on the search term
  const filteredCourses = courses.filter(course => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return (
      course.title.toLowerCase().includes(lowercasedSearchTerm) ||
      course.description.toLowerCase().includes(lowercasedSearchTerm) ||
      course.badgeText.toLowerCase().includes(lowercasedSearchTerm)
    );
  });

  // If categoryDisplayId is available, filter courses by categoryDisplayId first
  const filteredAndSortedCourses = categoryDisplayId
    ? filteredCourses.filter((course) => course.categoryDisplayId === (categoryDisplayId))
    : filteredCourses;

  // Sort courses by fee
  const sortedCourses = [...filteredAndSortedCourses].sort((a, b) => {
    if (sortOption === 'asc') {
      return a.fee - b.fee;
    } else {
      return b.fee - a.fee;
    }
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCourses = sortedCourses.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedCourses.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  if(loading)
    {
  return (
    <div className="course-details-container">
  <p className='text text-center'>Loading courses...</p>
  </div>
  );
    }
else
{
  return (
    <div className="courses">
     {!isImported ? (
  <Helmet>
    <title>{title}</title>
    <meta
      name="description"
      content="Discover top programming and development courses on Course Echo. Learn Java, Angular, Flask, AWS, and more with in-depth tutorials and hands-on projects."
    />
    <meta
      name="keywords"
      content="Course Echo, programming courses, development tutorials, Java, Angular, Flask, AWS, MySQL, PHP, learn programming, coding courses"
    />
    <meta name="author" content="Course Echo Team" />
    <meta
      property="og:title"
      content={title}
    />
    <meta
      property="og:description"
      content="Explore programming courses on Course Echo - Comprehensive tutorials in Java, Angular, Flask, AWS, and more. Kickstart your coding journey today!"
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://courseecho.com/courses" />
    <meta
      property="og:image"
      content="https://courseecho.com/logo.png"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:title"
      content={title}
    />
    <meta
      name="twitter:description"
      content="Learn programming with Course Echo. Access tutorials and courses on Java, Angular, Flask, AWS, and more!"
    />
    <meta
      name="twitter:image"
      content="https://courseecho.com/logo.png"
    />
    <meta name="twitter:creator" content="@CourseEcho" />
  </Helmet>
) : (
  <></> // Using an empty fragment instead of <br> for cleaner output when not imported
)}

      <h2 className='header'>{categoryDisplayId ? `Courses in Category ${categoryDisplayId}` : 'Explore Our Courses'}</h2>

      {/* Search Bar and Clear Filter Button */}
      <div className="search-sort-container">
        <input
          type="text"
          placeholder="Search courses..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />

        {/* Clear Search Icon */}
        {searchTerm && (
          <button className="clear-button" onClick={clearFilters}>
            <span className="clear-icon">✖</span>
          </button>
        )}

        {/* Sort Dropdown */}
        <select value={sortOption} onChange={handleSortChange} className="sort-dropdown">
          <option value="asc">Price: Low to High</option>
          <option value="desc">Price: High to Low</option>
        </select>
      </div>

      {/* Items Per Page Dropdown */}
      <div className="items-per-page-container">
        <label htmlFor="items-per-page" className="items-per-page-label">Items per page:</label>
        <select id="items-per-page" value={itemsPerPage} onChange={handleItemsPerPageChange} className="items-per-page-select">
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>

      {/* Display Courses */}
      <div className="courses-container">
        <div className="row">
          {currentCourses.map((course) => (
            <div className="col-md-3 col-sm-6 mb-3" key={course.id}>
              <CourseCard course={course}  onAddToCart={handleAddToCart} />
            </div>
          ))}
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        {totalPages > 1 && (
          <>
            <button
              className="page-button"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              &laquo; First
            </button>
            <button
              className="page-button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt; Prev
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="page-button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next &gt;
            </button>
            <button
              className="page-button"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last &raquo;
            </button>
          </>
        )}
      </div>
    </div>
  );
}
};

export default Courses;
