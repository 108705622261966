import axios from 'axios';
import { Course } from '../data/coursesData';
import { ENDPOINT } from '../utils/Endpoints';
import { CourseCategory } from '../data/categoryData';

const COURSE_API_URL = `${ENDPOINT}/courses/short`;
const CAETGORY_API_URL = `${ENDPOINT}/courses/category`;
const USER_COURSE_API_URL = `${ENDPOINT}/courses/user/related`;


export const getCourses = async (): Promise<Course[]> => {
  try {
    const response = await axios.get(COURSE_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};


export const getCourseById = async (courseId: string): Promise<Course> => {
      const response = await axios.get<Course>(`${ENDPOINT}/courses/${courseId}/related`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
  
        },
      });
      return response.data;
  };

export const getCourseByCategoryDisplayId = async (courseId: string): Promise<Course[]> => {
  try {
    const response = await axios.get(`${ENDPOINT}/courses/categroy/${courseId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`

      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

  export const getCategory = async (): Promise<CourseCategory[]> => {
    try {
      const response = await axios.get(CAETGORY_API_URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching courses:', error);
      throw error;
    }
  };

  export const getUserCourses = async (): Promise<Course[]> => {
    try {
      const response = await axios.get(USER_COURSE_API_URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching courses:', error);
      throw error;
    }
  };