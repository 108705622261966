import React from 'react';
import './CourseResources.css';
import { Course } from '../data/coursesData';

interface CourseResourceProps {
  course: Course; // Single course object, not an array
}

const CourseResources: React.FC<CourseResourceProps> = ({ course }) => {
  // Default to empty array if Resources is undefined
  const resources = course.Resources || [];

  return (
    <section className="course-resource">
      <h3>Resources</h3>
      <ul>
        {resources.length > 0 ? (
          resources.map((resource) => (
            <li key={resource.id}>
              <h4>{resource.title}</h4>
              <p>{resource.description}</p>
              {resource.externalLink && (
                <a href={resource.externalLink} target="_blank" rel="noopener noreferrer">
                  Visit Resource
                </a>
              )}
            </li>
          ))
        ) : (
          <p>No resources available.</p>
        )}
      </ul>
    </section>
  );
};

export default CourseResources;
