import React from 'react';
import './CoursePrerequisites.css';
import { Course } from '../data/coursesData';

interface CoursePrerequisiteProps {
  course: Course;
}

const CoursePrerequisites: React.FC<CoursePrerequisiteProps> = ({ course }) => {
  // Check if Prerequisites is defined and has a length
  const prerequisites = course.Prerequisites ?? [];

  return (
    <section className="course-prerequisite">
      <h3>Prerequisites</h3>
      <ul>
        {prerequisites.length > 0 ? (
          prerequisites.map((prerequisite) => (
            <li key={prerequisite.id}>
              <h4>{prerequisite.title}</h4>
              <p>{prerequisite.description}</p>
            </li>
          ))
        ) : (
          <p>No prerequisites available.</p>
        )}
      </ul>
    </section>
  );
};

export default CoursePrerequisites;
