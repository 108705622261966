// src/data/coursesData.ts

export interface Course {
  id: number;
  title: string;
  description: string;
  keywords:string;
  image: string;
  fee: number;
  rating: number;
  numberOfTopics: number;
  badgeText: string; // Badge text (e.g., "Premium")
  source: string; // Add this property to define if the course is local or external
  externalLink?: string; // Optional external link
  categoryDisplayId?: string;
  courseDisplayId?: string;
  Topics: Array<Topics>;
  Resources?: Array<CourseResource>;
  Prerequisites?: Array<CoursePrerequisit>;
  Faqs?: Array<CourseFaq>;
  Tests?: Array<CourseTest>;
  objective?: string;
  CourseFor?: Array<CourseFor>;
  authoor?: Array<CourseAuthor>;
  projects?:Array<CourseProject>;
  certificate?:Array<CourseCertificate>;
  CoursePerksBenefits?:Array<CoursePerksBenifites>
  learnings?:CourseLearnings;
  coupons?:Array<CourseCoupons>;
  discount?:number;
  CourseBulletPoints?:Array<CourseBulletPoints>;
  orders:Array<CourseOrders>

}
export interface CourseOrders
{
  id?:number;
  userId:string;
  quantity:number;
  totalPrice:number;
  orderDate:string;
  status:string;
  created_at:string;
  updated_at:string;
  email?:string;
  mobile?:string;
  name?:string;
  courseId:number;
}
export interface CourseBulletPoints
{
  id?:number;
  title:string;
  icon?:string;
  courseId:number;
}
export interface CourseCoupons
{
id?:number;
code:string;
price:number;
isExpired:boolean;
courseId:number;

}
export interface CourseLearnings
{
id?:number;
title:string;
topicList?:Array<CourseLearningsTopics>
courseId:number;

}
export interface CourseLearningsTopics
{
  id?:number;
  title:string;
  icon?:string;
  learningId:string;
}
export interface CoursePerksBenifites
{
id?:number;
title:string;
description:string;
icon?:string;
courseId:number;

}
export interface CourseCertificate
{
id?:number;
title:string;
description:string;
image?:string;
courseId:number;

}
export interface CourseProject
{
id?:number;
title:string;
description:string;
image?:string;
link?:string;
features?:Array<ProjectFeatures>;
courseId:number;

}
export interface ProjectFeatures
{
id?:number;
title:string;
courseId:number;

}
export interface CourseAuthor
{
id?:number;
title:string;
description:string;
image?:string;
linkedin?:string;
courseId:number;

}

export interface CourseFor
{
id?:number;
title:string;
description:string;
courseId:number;

}
export interface CourseTest {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  courseId:number;
  options: Array<string>;
  correctAnswer: string;
}
export interface CourseFaq {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  courseId:number;

}
export interface CoursePrerequisit {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  courseId:number;

}
export interface CourseResource {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  courseId:number;

}
export interface Topics {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  source: string;
  isFree: boolean;
  courseId: number;
}

const courses: Course[] = [];

export default courses;
