// src/components/Footer.tsx
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="text-center py-3 mt-5">
      <div className="footer-bg"></div>
      <div className="container">
        <div className="footer-links">
          <Link className="footer-link" to="/about">
            About
          </Link>
          <Link className="footer-link" to="/contact">
            Contact
          </Link>
          <Link className="footer-link" to="/career">
            Career
          </Link>
          <Link className="footer-link" to="/privacy-policy">
            Privacy Policy
          </Link>
          <Link className="footer-link" to="/terms-and-conditions">
            Terms and Conditions
          </Link>
          <Link className="footer-link" to="/cancellation-refund-policy">
            Cancellation & Refund Policy
          </Link>
          <Link className="footer-link" to="/shipping-delivery-policy">
            Shipping & Delivery Policy
          </Link>
        </div>

        {/* Social Media Links */}
        <div className="social-links mt-3">
          <a href="https://x.com/CourseEcho" target="_blank" rel="noopener noreferrer" className="social-icon text-primary">
            <i className="fa fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/courseecho" target="_blank" rel="noopener noreferrer" className="social-icon text-danger">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="https://www.facebook.com/courseecho" target="_blank" rel="noopener noreferrer" className="social-icon text-info">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="https://www.linkedin.com/company/courseecho" target="_blank" rel="noopener noreferrer" className="social-icon text-primary">
            <i className="fa fa-linkedin"></i>
          </a>
          <a href="https://www.youtube.com/@CourseEcho" target="_blank" rel="noopener noreferrer" className="social-icon text-danger">
            <i className="fa fa-youtube"></i>
          </a>
        </div>

        <p className="mb-0">© 2024 courseecho</p>
        <p className="mb-0">All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
