import React, { useState } from 'react';
import Courses from './Courses';
import Category from '../components/Category';
import courses from '../data/coursesData';
import { useNavigate } from 'react-router-dom';
import HeroSection from '../components/HeroSection';
import { APPLYNOW } from '../utils/Endpoints';
import { Helmet } from 'react-helmet-async';
import { PROJECTNAME } from '../utils/constants';
const Home: React.FC = () => {
  const navigate = useNavigate();
  const [selectedCategoryDisplayId, setSelectedCategoryDisplayId] = useState<string | null>(null);

  // Filter courses based on the selected category
  const filteredCourses = selectedCategoryDisplayId
    ? courses.filter((course) => course.categoryDisplayId === selectedCategoryDisplayId)
    : courses;
  // Handle category selection and navigate to the Courses page
  const handleCategoryClick = (categoryDisplayId: string) => {
    setSelectedCategoryDisplayId(categoryDisplayId === selectedCategoryDisplayId ? null : categoryDisplayId); // Toggle category
    navigate(`/courses?course-category=${categoryDisplayId === selectedCategoryDisplayId ? '' : categoryDisplayId}`); // Navigate with categoryDisplayId
  };


  return (
    <div className="home">
      <Helmet>
      <title>Course Echo | Online and Offline Classes, Paid & Free Courses, Hiring Expert Trainers</title>

      <meta name="description" content="Course Echo offers top-notch offline and online programming courses, including free and paid options. Hiring expert freelance trainers for personalized learning." />
    <meta name="keywords" content="Course Echo, programming tutorials, online classes, offline courses, free programming courses, paid programming courses, hiring freelance trainers, coding classes" />
    <meta name="author" content={PROJECTNAME} />
    
    <meta property="og:title" content="Course Echo | Online and Offline Classes, Paid & Free Courses, Hiring Expert Trainers" />
    <meta property="og:description" content="Course Echo offers top-notch offline and online programming courses, including free and paid options. Hiring expert freelance trainers for personalized learning." />
    <meta property="og:image" content="https://courseecho.com/logo.png" />
    <meta property="og:url" content="https://courseecho.com" />
    <meta property="og:type" content="website" />

    <meta name="twitter:card" content="" />
    <meta name="twitter:title" content="Course Echo | Online and Offline Classes, Paid & Free Courses, Hiring Expert Trainers" />
    <meta name="twitter:description" content="Course Echo offers top-notch offline and online programming courses, including free and paid options. Hiring expert freelance trainers for personalized learning." />
    <meta name="twitter:image" content="https://courseecho.com/logo.png" />
    <meta name="twitter:creator" content="@CourseEcho" />
      </Helmet>
      <HeroSection
        imageUrl="/assets/images/backgrounds/great-product-by-great-team.png"
        link={APPLYNOW}
        heading="Great Product is built by great teams"
        paragraph="Join our community of learners and industry experts. We provide top-tier tech courses designed to help you build in-demand skills, advance your career, and achieve your goals in the ever-evolving tech landscape."
        buttonText="Join Us"
      />
      <Category onCategoryClick={handleCategoryClick} selectedCategoryDisplayId={selectedCategoryDisplayId} />
      <Courses courses={filteredCourses} isImported={true} />
    </div>
  );
};

export default Home;
