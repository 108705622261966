// CancellationAndRefund.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { PROJECTNAME } from '../../utils/constants';

const CancellationAndRefund: React.FC = () => {
    const date= (new Date("12-08-2024")).toString();

  return (
    <div className="page-container">
      <Helmet>
  <title>Cancellation and Refund Policy - Course Echo</title>
  <meta name="description" content="Review our Cancellation and Refund Policy for all courses offered by Course Echo. Understand the terms and conditions for cancellations and refund requests." />
  <meta name="keywords" content="cancellation policy, refund policy, course cancellation, e-learning, Course Echo" />
  <meta name="author" content={PROJECTNAME} />

  {/* Open Graph (OG) meta tags */}
  <meta property="og:title" content="Cancellation and Refund Policy - Course Echo" />
  <meta property="og:description" content="Review our Cancellation and Refund Policy for all courses offered by Course Echo. Understand the terms and conditions for cancellations and refund requests." />
  <meta property="og:image" content="https://courseecho.com/logo.png" /> {/* Replace with actual image URL for the Cancellation and Refund page */}
  <meta property="og:url" content="https://courseecho.com/cancellation-refund-policy" /> {/* Replace with the specific page URL */}
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CourseEcho" />

  {/* Twitter Card meta tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Cancellation and Refund Policy - Course Echo" />
  <meta name="twitter:description" content="Review our Cancellation and Refund Policy for all courses offered by Course Echo. Understand the terms and conditions for cancellations and refund requests." />
  <meta name="twitter:image" content="https://courseecho.com/logo.png" /> {/* Replace with actual image URL for the Cancellation and Refund page */}
  <meta name="twitter:creator" content="@CourseEcho" /> {/* Replace with your Twitter handle */}
</Helmet>

      <h1>Cancellation and Refund Policy</h1>
      <p>Last updated: {date}</p>
      <p>We strive to ensure complete satisfaction. However, if you need to cancel or request a refund, please follow these guidelines.</p>
      <Link to="/">Back to Home</Link>
    </div>
  );
};

export default CancellationAndRefund;
