import React, { useState, useEffect } from 'react';
import { getCart, removeFromCart, clearCart } from '../services/CartService';
import './CartPage.css';
import { ENDPOINT } from '../utils/Endpoints';
import { UserModel } from './profile/model/User.model';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { RAZRPAYKEY, RAZRPAYSECRETKEY } from '../utils/Keys';
import { PROJECTNAME } from '../utils/constants';

interface CartItem {
  courseId: number;
  title: string;
  fee: number;
  quantity: number;
}

const CartPage: React.FC = () => {
  useEffect(() => {
    // Check if the script is already present
    if (!document.querySelector(`script[src="https://checkout.razorpay.com/v1/checkout.js"]`)) {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [user, setUser] = useState<UserModel | null>(null);
  const [loading, setLoading] = useState(false);
  const { login,isAuthenticated } = useAuth(); // Simulate login after signup
  const [prefill, setPrefill] = useState({ id: '', name: '', email: '', contact: '', password: '', insertDate: new Date(), updateDate: new Date() });
  const [formErrors, setFormErrors] = useState({ name: '', email: '', contact: '' });
  let userData: any = {}
  const total = cartItems.reduce((acc, item) => acc + item.fee * item.quantity, 0);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        const email = localStorage.getItem("email");
        if (email) {
          const response = await axios.get(`${ENDPOINT}/users/${email}`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
          }
          });
          if (response.status === 200) {
            setUser(response.data);
            setPrefill({
              id: response.data.id,
              name: response.data.name,
              email: response.data.email,
              contact: response.data.mobile || '',
              password: response.data.password || '',
              insertDate: response.data.insertDate || new Date(), updateDate: response.data.updateDate || new Date()
            });
          }
        }
      } catch (err) {
        console.error('Error fetching user profile:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    const items = getCart();
    setCartItems(items);
  }, []);

  const handleRemoveFromCart = (courseId: number) => {
    removeFromCart(courseId);
    setCartItems(getCart());
  };

  const handleClearCart = () => {
    clearCart();
    setCartItems([]);
  };
  const updatePaymentStatus=async (payload:any) => {
    setLoading(true);
    payload.status = 'Completed'
    const response = await axios.put(`${ENDPOINT}/orders/update/${payload.id}`,payload,{
      headers:{
        Authorization: `Bearer ${localStorage.getItem("token")}`

      }
    });
      if (response.status === 200) {
        alert("Great , thanks for purchasing course")
        navigate('/dashboard');
      }
      else
      {
        alert("Error: while updateing status")
      }
      setLoading(false);
  };
  const handlePayment = async () => {
    if (cartItems.length > 1) {
      alert("Please buy only one course at a time")
      return;
    }
    // Form validation before proceeding with payment
    if (!prefill.name || !prefill.email || !prefill.contact) {
      setFormErrors({
        name: !prefill.name ? 'Name is required' : '',
        email: !prefill.email ? 'Email is required' : '',
        contact: !prefill.contact ? 'Contact number is required' : '',
      });
      return;
    }

    setFormErrors({ name: '', email: '', contact: '' }); // Reset errors

    setLoading(true);
    try {
      // check if user alredy there
      // const oldUserResponse = await axios.post(`${ENDPOINT}/users/verify-add/`,prefill);
      // if (oldUserResponse.status === 200) {
      //   userData = oldUserResponse.data
      //   prefill.password = ''
      //   prefill.id = oldUserResponse.data.id
      //   login(prefill.email)
      // }
      let payload = {
        courseId: cartItems[0].courseId,
        email: prefill.email,
        name: prefill.name,
        mobile:prefill.contact,
        orderDate: new Date(),
        quantity: cartItems[0].quantity,
        status: "Processing",
        totalPrice: total,
        userId: prefill.id
      }

      const response = await fetch(`${ENDPOINT}/orders/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`

        },
        body: JSON.stringify(payload),
      });

      if (response.status !==201) {
        setLoading(false);
        throw new Error('Failed to create order');
      }

      const order = await response.json();
      console.log('Order data:', order);
      const amountInPaise = order.data.totalPrice * 100; // Convert to paise if the currency is INR

      const options = {
        key: RAZRPAYKEY, // Replace with your Razorpay key ID
        key_secret:RAZRPAYSECRETKEY,
        amount: amountInPaise,
        currency: 'INR',
        name: 'Order',
        description: 'Order Payment',
        id: order.data.id,
        // order_id: order.data.id,
        handler: (response: any) => {
          console.log(response)
          alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
          updatePaymentStatus(order.data)
          handleClearCart();
        },
        prefill: {
          name:prefill.name,
          contact:prefill.contact,
          email:prefill.email
        },
        theme: {
          color: '#3399cc',
        },
      };
      const razorpay = new (window as any).Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.log(error)
      alert('Failed to initiate payment. Please try again.');
    } finally {
      setLoading(false);
    }

  };


  if (loading) {
    return (
      <div className="carts mt-5">
        <p>In Process...</p>
      </div>
    );
  }

  return (
    <div className="carts mt-5">
       <Helmet>
  <title>Cart - Course Echo | Checkout Your Courses</title>
  <meta name="description" content="Review your selected courses and proceed to checkout to secure your spot. Easily manage your cart and complete your purchase with our seamless online process." />
  <meta name="keywords" content="cart, checkout, e-learning, course purchase, online classes" />
  <meta name="author" content={PROJECTNAME} />

  {/* Open Graph (OG) meta tags */}
  <meta property="og:title" content="Cart - Checkout Your Courses" />
  <meta property="og:description" content="Review your selected courses and proceed to checkout to secure your spot. Easily manage your cart and complete your purchase with our seamless online process." />
  <meta property="og:image" content="https://courseecho.com/logo.png" /> {/* Replace with actual image URL for the Cart page */}
  <meta property="og:url" content="https://courseecho.com/cart" /> {/* Replace with the specific Cart page URL */}
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CourseEcho" />

  {/* Twitter Card meta tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Cart - Checkout Your Courses" />
  <meta name="twitter:description" content="Review your selected courses and proceed to checkout to secure your spot. Easily manage your cart and complete your purchase with our seamless online process." />
  <meta name="twitter:image" content="https://courseecho.com/logo.png" /> {/* Replace with actual image URL for the Cart page */}
  <meta name="twitter:creator" content="@CourseEcho" /> {/* Replace with your Twitter handle */}
</Helmet>

      <h2 className="header">Your Cart</h2>
      {cartItems.length === 0 ? (
        <p className="text-center">Your cart is empty. Add some items to your cart to get started!</p>
      ) : (
        <div className="cart-items">
          {cartItems.map((item) => (
            <div key={item.courseId} className="card mb-3 shadow-sm border-light">
              <div className="card-body formDiv d-flex justify-content-between align-items-center">
                <div className='formDiv'>
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text">Fee: ₹{item.fee}</p>
                  <p className="card-text">Quantity: {item.quantity}</p>
                </div>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleRemoveFromCart(item.courseId)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-between align-items-center">
            <button className="btn btn-warning" onClick={handleClearCart}>
              Clear Cart
            </button>
            <div>
              <h4>Total: ₹{total.toFixed(2)}</h4>
            </div>
          </div>
          {isAuthenticated ? (
            <div>
          <div className="m-2">
            <h3>Billing Details</h3>
            <form className='user-form'>
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    placeholder='Please enter name'
                    className="form-control"
                    value={prefill.name}
                    onChange={(e) => setPrefill({ ...prefill, name: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    placeholder='Please enter email'
                    className="form-control"
                    value={prefill.email}
                    onChange={(e) => setPrefill({ ...prefill, email: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    id="contact"
                    placeholder='Please enter mobile no'
                    className="form-control"
                    value={prefill.contact}
                    onChange={(e) => setPrefill({ ...prefill, contact: e.target.value })}
                  />
                </div>
              </div>
            </form>
          </div>

  <button className="btn btn-primary mt-3" onClick={handlePayment}>
    Proceed to Pay ₹{total.toFixed(2)}
  </button>
  </div>
) : (
  <div>
  <p className="text-danger">Please login first to proceed next!</p>
  <Link to='/login' className='btn'>Login now</Link>
  </div>
)}

        </div>
      )}
    </div>
  );
};

export default CartPage;
