import React, { useEffect, useState } from 'react';
import './UserCourses.css'; // Add styles for the courses
import { getUserCourses } from '../services/CourseService';
import { Course } from '../data/coursesData';
import CourseCard from './CourseCard';

const UserCourses: React.FC = () => {
  const [cartItems, setCartItems] = useState<number>(0);
  const [courses, setCourses] = useState<Course[]>([]); // State to store courses
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const handleAddToCart = () => {
    setCartItems(cartItems + 1);
  };
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const userCourses = await getUserCourses();
        setCourses(userCourses);
      } catch (err) {
        setError('Failed to fetch courses. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  if (loading) {
    return <div>Loading courses...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container courses-container">
      <div className="row">
        {courses.map((course) => (
          <div className="col-sm-12 col-md-6 col-lg-6">
        <CourseCard course={course} onAddToCart={handleAddToCart} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserCourses;