import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import './Profile.css'; // Import your custom CSS for the profile
import { ENDPOINT } from '../../utils/Endpoints';
import { Helmet } from 'react-helmet-async';
import UserCourses from '../../components/UserCourses';

const Profile: React.FC = () => {
  const [user, setUser] = useState<any>(null); // Store user profile data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const [activeSection, setActiveSection] = useState<'profile' | 'courses' | 'settings'>('profile'); // Track active section
  const { logout } = useAuth();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const email = localStorage.getItem('email');
        const response = await axios.get(`${ENDPOINT}/users/${email}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          setUser(response.data);
        } else {
          setError('Failed to load profile. Please try again later.');
        }
      } catch (err: any) {
        setError('Error fetching profile data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleLogout = () => {
    logout();
    window.location.href = '/login'; // Redirect to login after logout
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="profile-container row">
      <Helmet>
        <title>User Profile - Course Echo</title>
      </Helmet>

      <div className="sdBar col-sm-12 col-md-3 col-lg-3">
        <h3>Section</h3>
        <ul>
          <li>
            <button
              className={`btn ${activeSection === 'profile' ? 'btn-active' : ''}`}
              onClick={() => setActiveSection('profile')}
            >
              Profile
            </button>
          </li>
          <li>
            <button
              className={`btn ${activeSection === 'courses' ? 'btn-active' : ''}`}
              onClick={() => setActiveSection('courses')}
            >
              Courses
            </button>
          </li>
          <li>
            <button
              className={`btn ${activeSection === 'settings' ? 'btn-active' : ''}`}
              onClick={() => setActiveSection('settings')}
            >
              Settings
            </button>
          </li>
          <li>
            <button className="btn btn-danger" onClick={handleLogout}>
              Logout
            </button>
          </li>
        </ul>
      </div>

      <div className="sdBarContent col-sm-12 col-md-9 col-lg-9">
        {activeSection === 'profile' && (
          <div>
            <h2>Profile</h2>
            <p><strong>Name:</strong> {user.name}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Mobile:</strong> {user.mobile}</p>
          </div>
        )}

        {activeSection === 'courses' && <UserCourses />}

        {activeSection === 'settings' && (
          <div>
            <h2>Settings</h2>
            <p>Settings coming soon!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;