// ShippingAndDelivery.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { PROJECTNAME } from '../../utils/constants';

const ShippingAndDelivery: React.FC = () => {
    const date= (new Date("12-08-2024")).toString();
  return (
    <div className="page-container">
      <Helmet>
  <title>Shipping and Delivery Policy - Course Echo</title>
  <meta name="description" content="Read our Shipping and Delivery Policy to understand how we ensure efficient and secure delivery of your products." />
  <meta name="keywords" content="shipping policy, delivery policy, product delivery, Course Echo, secure delivery" />
  <meta name="author" content={PROJECTNAME} />

  {/* Open Graph (OG) meta tags */}
  <meta property="og:title" content="Shipping and Delivery Policy - Course Echo" />
  <meta property="og:description" content="Read our Shipping and Delivery Policy to understand how we ensure efficient and secure delivery of your products." />
  <meta property="og:image" content="https://courseecho.com/logo.png" /> {/* Replace with actual image URL for the Shipping Policy page */}
  <meta property="og:url" content="https://courseecho.com/shipping-delivery-policy" /> {/* Replace with the specific page URL */}
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CourseEcho" />

  {/* Twitter Card meta tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Shipping and Delivery Policy - Course Echo" />
  <meta name="twitter:description" content="Read our Shipping and Delivery Policy to understand how we ensure efficient and secure delivery of your products." />
  <meta name="twitter:image" content="https://courseecho.com/logo.png" /> {/* Replace with actual image URL for the Shipping Policy page */}
  <meta name="twitter:creator" content="@CourseEcho" /> {/* Replace with your Twitter handle */}
</Helmet>

      <h1>Shipping and Delivery Policy</h1>
      <p>Last updated: {date}</p>
      <p>We ensure your products are delivered efficiently and securely. Please read our shipping and delivery policy for more details.</p>
      <Link to="/">Back to Home</Link>
    </div>
  );
};

export default ShippingAndDelivery;
