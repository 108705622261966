import React, { useEffect } from 'react';

const TawkToWidget: React.FC = () => {
  useEffect(() => {
    const Tawk_API = (window as any).Tawk_API || {};
    const Tawk_LoadStart = new Date();

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/6755ccc72480f5b4f5aa78f7/1iejifr8c';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return null; // No visible UI, script integration only
};

export default TawkToWidget;
