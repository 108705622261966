import React, { useEffect, useState } from 'react';
import { Course, Topics } from '../data/coursesData';
import './CourseDetails.css'; // Add your styling if needed
import { getCourseById } from '../services/CourseService';
import { Accordion } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { addToCart } from '../services/CartService';
import { Helmet } from 'react-helmet-async';
import TestComponent from './TestComponent';
import { PROJECTNAME } from '../utils/constants';
import CoursePrerequisites from './CoursePrerequisites';
import CourseResources from './CourseResources';
import CourseFaqs from './CourseFaqs';
import ReactPlayer from 'react-player';

interface CourseDetailsProps {
  course: Course;
}
const CourseDetails: React.FC<CourseDetailsProps> = ({ course }) => {
  const [isPlaying, setIsPlaying] = useState(false); // To track if video is playing or paused
  const [progress, setProgress] = useState(0); // To track the progress of the video
  const [volume, setVolume] = useState(0.8); // Default volume set to 80%
  const [duration, setDuration] = useState(0); // To track the video's total duration
  const [played, setPlayed] = useState(0); // The percentage of video that has been played
  const isLoggedIn = localStorage.getItem('token') ? true : false;
  const navigate = useNavigate();
  const { updateCartItems } = useCart();
  const handleAddToCart = () => {
    addToCart(courseResponse); // Add the course to the cart
    updateCartItems(); // Update the cart count in Navbar
    navigate('/cart')
  };
  const [loading, setLoading] = useState(true); // State to handle loading
  const { courseDisplayid, courseid } = useParams<{ courseDisplayid: string; courseid: string }>();

  // State to manage the active FAQ item
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle function to open/close an FAQ item
  const handleToggle = (index: any) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close if the same FAQ is clicked again
    } else {
      setActiveIndex(index);
    }
  };
  const [courseResponse, setCourse] = useState<Course>(course);

  const [activeTab, setActiveTab] = useState<string>('details');
  const [selectedTopic, setSelectedTopic] = useState<Topics | null>(null);

  // Fetch course data when the component mounts or courseid changes
  useEffect(() => {
    const fetchCourseDetails = async () => {
      const courseData = await getCourseById(courseid || '');
      setCourse(courseData);
      setLoading(false)
      if (courseData.orders.length > 0 || courseData.fee < 1) {
        setActiveTab('Topics');
      }
    };

    fetchCourseDetails();
  }, [courseid]);

  // Automatically select the first topic when the 'Topics' tab is selected
  // Automatically select the first topic when the 'Topics' tab is selected
  useEffect(() => {
    if (activeTab === 'Topics' && courseResponse && courseResponse.Topics && courseResponse.Topics.length > 0) {
      setSelectedTopic(courseResponse.Topics[0]); // Set the first topic as the default selected topic
    }
  }, [activeTab, courseResponse]);

  // Play/Pause the video
  const togglePlay = () => setIsPlaying(!isPlaying);

  // Update progress when the video is playing
  const handleProgress = (state: { played: number; playedSeconds: number }) => {
    setProgress(state.playedSeconds); // In seconds
    setPlayed(state.played * 100); // In percentage
  };
  // Update video duration
  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  // Seek the video to a specific time
  const handleSeek = (e: any) => {
    const seekTo = (e.target.value / 100) * duration; // Convert percentage to seconds
    setProgress(seekTo);
    setPlayed(e.target.value);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'details':
        return (
          <div className='container'>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="details-image-container">
                  <img
                    src={courseResponse?.image}
                    alt={courseResponse?.title}
                    className="img-fluid"
                  />
                </div>

              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 card-body card-body-radius">

                <div className="details-content card-body p-3 card-body-radius">
                  <h3>{courseResponse?.title}</h3>

                  <div className="course-details ">
                    {courseResponse?.fee < 1 ? (
                      <div className="course-badge free-course">Free</div>
                    ) : (
                      <div className="course-badge paid-course">
                        👑{courseResponse?.fee} INR</div>

                    )}

                    <div className="rating ">
                      <span>{courseResponse?.rating} ⭐</span>
                    </div>
                    <div className='course-badge course-topics'>Topics: {courseResponse?.Topics.length}</div>
                    <div className="course-badge free-course">{courseResponse?.badgeText}</div>
                  </div>
                  <p className="card-text truncate" title={courseResponse?.description}>{courseResponse?.description}</p>

                  <div className='button-container'>
                  {courseResponse?.fee < 1 || courseResponse.orders?.length > 0 ? (
                                        <button onClick={() => setActiveTab('Topics')} className='btn btn-primary viewBtn'>Watch Now</button>

                    ) : null} 

                    {courseResponse?.fee > 0 && courseResponse.orders?.length < 1 ? (
                      <button onClick={handleAddToCart} className="btn btn-danger applyBtn">Buy Now</button>
                    ) : null}  {/* Don't show the button when orders length > 0 */}

                    {/* Only show the "Proceed to Enroll" button if orders.length is 0 */}
                    {courseResponse?.orders?.length === 0 && (
                      <a href={`https://wa.me/8677040031?text=Course: ${courseResponse?.courseDisplayId}.I want to enroll`} target="_blank" rel="noopener noreferrer"
                        className="btn btn-primary applyBtn">
                        Proceed to Enroll
                      </a>
                    )}
                  </div>

                </div>
              </div>

            </div>


          </div>
        );


      case 'Topics':
        return (
          <div className="Topics-tab">
            <div className='row'>
              <div className='col-sm-12 col-md-6 col-lg-6'>
                <div className='topic-card'>
                  {courseResponse?.Topics.length ? (
                    <ul>
                      {courseResponse?.Topics.map((topic) => (
                        <li className="topicLi" key={topic.id} onClick={() => setSelectedTopic(topic)}
                        >
                          <div className="topic-item">
                            <p><i className='fa fa-video-camera'></i> {topic.title}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Topics available.</p>
                  )}
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'>

                <div className="video-container">
                  {courseResponse?.fee < 1 ? (
                    <ReactPlayer
                      title={selectedTopic?.title}
                      url={selectedTopic?.externalLink}
                      playing={isPlaying}
                      controls={true}
                      volume={volume}
                      onProgress={handleProgress}
                      onDuration={handleDuration}
                      onSeek={handleSeek}
                      width="100%"
                      height="100%"
                    />
                  ) : courseResponse?.orders?.length > 0 ? (
                    <ReactPlayer
                      title={selectedTopic?.title}
                      url={selectedTopic?.externalLink}
                      playing={isPlaying}
                      controls={true}
                      volume={volume}
                      onProgress={handleProgress}
                      onDuration={handleDuration}
                      onSeek={handleSeek}
                      width="100%"
                      height="100%"
                    />
                  ) : !isLoggedIn ? (
                    <Link
                      to="/login"
                      className="btn btn-primary viewBtn enroll-button mt-2"
                    >
                      Login first to access this
                    </Link>
                  ) : (
                    <button
                      onClick={handleAddToCart}
                      className="btn btn-primary applyBtn"
                    >
                      Buy Now to start learning
                    </button>
                  )}
                </div>



              </div>
            </div>
          </div>
        );
      case 'resources':
        return (
          <div className="resources-tab">
            <div className='container'>
              <div className='col-sm-12'>
                {courseResponse?.Resources?.length ? (
                  <CourseResources course={courseResponse} />

                ) : (
                  <p>No resources available.</p>
                )}
              </div>
            </div>
          </div>
        );

      case 'prerequisites':
        return (
          <div className="resources-tab">
            <div className='container'>
              <div className='col-sm-12'>
                {courseResponse?.Prerequisites?.length ? (
                  <CoursePrerequisites course={courseResponse} />
                ) : (
                  <p>No prerequisit available.</p>
                )}
              </div>
            </div>
          </div>
        );

      case 'faq':
        return (
          <div className="resources-tab">
            <div className='container'>
              <div className='col-sm-12'>
                {courseResponse?.Faqs?.length ? (
                  <CourseFaqs course={courseResponse} />

                ) : (
                  <p>No faq available.</p>
                )}
              </div>
            </div>
          </div>
        );
      case 'test':
        return (
          <div className="tests-tab">
            <div className="container">
              <div className="col-sm-12">
                <h3>{courseResponse.title} Quiz</h3>
                <p>
                  You can test your {courseResponse.title} skills with '{PROJECTNAME}' Quiz.
                </p>
              </div>
            </div>
            <hr></hr>
            <div className="container">
              <div className="col-sm-12">
                <h6>The test</h6>
                <p>
                  The test contains ({courseResponse?.Tests?.length}) questions and there is no time limit.

                  The test is not official, it's just a nice way to see how much you know, or don't know, about {courseResponse.title}.
                </p>
              </div>
              <div className="col-sm-12">
                <h6>Count your score</h6>
                <p>
                  You will get 1 point for each correct answer. At the end of the Quiz, your total score will be displayed. Maximum score is ({courseResponse?.Tests?.length}) points.

                </p>
              </div>
            </div>
            <hr></hr>

            <div className='container'>
              <div className='col-sm-12'>
                {courseResponse?.Tests?.length ? (
                  <TestComponent tests={courseResponse.Tests} />

                ) : (
                  <p>No test available.</p>
                )}
              </div>
            </div>
          </div>
        );

      case 'enroll':
        return (
          <div>
            <h4>Enroll in Online Classes</h4>
            <a href={`https://wa.me/8677040031?text=Course: ${courseResponse?.courseDisplayId}.I want to enroll`} target="_blank" rel="noopener noreferrer"
              className="btn btn-primary">Proceed to Enroll</a>
          </div>
        );

      default:
        return null;
    }
  };
  if (loading) {
    return (
      <div className="course-details-container">
        <p className='text text-center'>Loading details...</p>
      </div>
    );
  }
  else {
    return (
      <div className="course-details-container">
        <Helmet>
          <title>{courseResponse?.title} | Course Echo</title>
          <meta name="description" content={courseResponse?.description} />
          <meta name="keywords" content={courseResponse?.keywords || 'courses, online learning, education'} />
          <meta name="author" content={PROJECTNAME} />
          <meta property="og:title" content={courseResponse?.title} />
          <meta property="og:description" content={courseResponse?.description} />
          <meta property="og:image" content={courseResponse?.image} />
          <meta property="og:url" content={`https://courseecho.com/course-details/${courseDisplayid}/${courseid}`} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={courseResponse?.title} />
          <meta name="twitter:description" content={courseResponse?.description} />
          <meta name="twitter:image" content={courseResponse?.image} />
          <meta name="twitter:url" content={`https://courseecho.com/course-details/${courseDisplayid}/${courseid}`} />
          <meta name="twitter:creator" content="@CourseEcho" />
        </Helmet>
        <div className="tabs">
          <div className="tab-buttons">
            {/* Details Tab */}
            <button
              className={`tab-button ${activeTab === 'details' ? 'active' : ''}`}
              onClick={() => setActiveTab('details')}
            >
              <i className='fa fa-info-circle'></i> Details
            </button>

            {/* Topics Tab */}
            <button
              className={`tab-button ${activeTab === 'Topics' ? 'active' : ''}`}
              onClick={() => setActiveTab('Topics')}
            >
              <i className='fa fa-book'></i> Topics ({courseResponse?.Topics.length})
            </button>

            {/* Resources Tab */}
            <button
              className={`tab-button ${activeTab === 'resources' ? 'active' : ''}`}
              onClick={() => setActiveTab('resources')}
            >
              <i className='fa fa-bookmark'></i> Resources ({courseResponse?.Resources?.length})
            </button>

            {/* Prerequisites Tab */}
            <button
              className={`tab-button ${activeTab === 'prerequisites' ? 'active' : ''}`}
              onClick={() => setActiveTab('prerequisites')}
            >
              <i className='fa fa-check-circle'></i> Prerequisites ({courseResponse?.Prerequisites?.length})
            </button>

            {/* FAQ Tab */}
            <button
              className={`tab-button ${activeTab === 'faq' ? 'active' : ''}`}
              onClick={() => setActiveTab('faq')}
            >
              <i className='fa fa-question-circle'></i> FAQ ({courseResponse?.Faqs?.length})
            </button>

            {/* Quiz Tab */}
            {courseResponse?.Topics.length && (
              <button
                className={`tab-button ${activeTab === 'test' ? 'active' : ''}`}
                onClick={() => setActiveTab('test')}
              >
                <i className='fa fa-pencil'></i> Quiz ({courseResponse?.Tests?.length})
              </button>
            )}

            {/* Enroll Tab */}
            {courseResponse?.Topics.length && (
              <button
                className={`tab-button ${activeTab === 'enroll' ? 'active' : ''}`}
                onClick={() => setActiveTab('enroll')}
              >
                <i className='fa fa-user-plus'></i> Enroll
              </button>
            )}
          </div>

        </div>
        <div className="tab-content">{renderTabContent()}</div>
        <hr></hr>
        <div className="container">
          {courseResponse?.CourseBulletPoints?.length ? (
            <div className="row">
              {courseResponse?.CourseBulletPoints.map((item, index) => (
                <div key={index} className="col-sm-12 col-md-6 mb-3">
                  <div className="d-flex align-items-center">
                    <div className="icon-container me-3">
                      <i className={`fa ${item.icon} fa-2x`}></i>
                    </div>
                    <div className="text-container">
                      <p className="mb-0">{item.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No bullet points available</p>
          )}
        </div>
        <div className='objective'>
          <div className="container">
            <div className="col-sm-12">
              <h1 className='detail-h1'>Our Objective</h1>
              <p>{courseResponse?.objective}</p>
            </div>
          </div>
        </div>
        <div className='course-for'>
          <div className="container">
            <div className="col-sm-12">
              <h1 className='detail-h1'>Perfect Fit for Learners Who...</h1>
            </div>
          </div>
          <div className="container">
            {courseResponse?.CourseFor && courseResponse.CourseFor.length > 0 ? (
              <div className='row'>
                {courseResponse.CourseFor.map((item, index) => (
                  <div className={`job-posting col-sm-12 col-md-4 col-lg-3`}>
                    <h2 className='border-bottom text-center'>{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p>No items available</p>
            )}
          </div>


        </div>

        <div className='perks'>
          <div className="container">
            <div className="col-sm-12">
              <h1 className='detail-h1'>Exclusive Perks & Rewards</h1>
            </div>
          </div>
          <div className="container">
            <div className="col-sm-12">
              {courseResponse?.CoursePerksBenefits && courseResponse.CoursePerksBenefits.length > 0 ? (
                <div className='row'>
                  {courseResponse.CoursePerksBenefits.map((item) => (
                    <div className="job-posting col-sm-12 col-md-2 col-lg-2 m-1">

                      <div className="perk-card">
                        <div className="perk-card-header">
                          <i className={item.icon}></i>
                          <h2 className="perk-card-title">{item.title}</h2>
                        </div>
                      </div>

                    </div>
                  ))}
                </div>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
        <div className="faq-container">
          <div className="container">
            <div className="col-sm-12">
              <h1 className="detail-h1">Frequently Asked Questions</h1>
            </div>
          </div>

          {courseResponse?.Faqs && courseResponse.Faqs.length > 0 ? (
            <div className="row">
              <Accordion defaultActiveKey="0" alwaysOpen>
                {courseResponse.Faqs.map((item, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>{item.description}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          ) : (
            <p></p>
          )}
        </div>
      </div>
    );
  }
};

export default CourseDetails;
