// PrivacyPolicy.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { PROJECTNAME } from '../../utils/constants';

const PrivacyPolicy: React.FC = () => {
    const date= (new Date("12-08-2024")).toString();
  return (
    <div className="page-container">
      <Helmet>
  <title>Privacy Policy - Course Echo</title>
  <meta name="description" content="Read our Privacy Policy to understand how Course Echo collects, uses, and discloses your personal information when you use our services." />
  <meta name="keywords" content="privacy policy, data protection, user privacy, Course Echo, personal information" />
  <meta name="author" content={PROJECTNAME} />

  {/* Open Graph (OG) meta tags */}
  <meta property="og:title" content="Privacy Policy - Course Echo" />
  <meta property="og:description" content="Read our Privacy Policy to understand how Course Echo collects, uses, and discloses your personal information when you use our services." />
  <meta property="og:image" content="https://courseecho.com/privacy-policy-image.png" /> {/* Replace with actual image URL for the Privacy Policy page */}
  <meta property="og:url" content="https://courseecho.com/privacy-policy" /> {/* Replace with the specific page URL */}
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CourseEcho" />

  {/* Twitter Card meta tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Privacy Policy - Course Echo" />
  <meta name="twitter:description" content="Read our Privacy Policy to understand how Course Echo collects, uses, and discloses your personal information when you use our services." />
  <meta name="twitter:image" content="https://courseecho.com/privacy-policy-image.png" /> {/* Replace with actual image URL for the Privacy Policy page */}
  <meta name="twitter:creator" content="@CourseEcho" /> {/* Replace with your Twitter handle */}
</Helmet>

      <h1>Privacy Policy</h1>
      <p>Last updated: {date}</p>
      <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and disclose your information when you use our service.</p>
      <Link to="/">Back to Home</Link>
    </div>
  );
};

export default PrivacyPolicy;
