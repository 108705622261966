import React from 'react';
import './CourseFaqs.css';
import { Course } from '../data/coursesData';


interface CourseFaqProps {
  course: Course;
}

const CourseFaqs: React.FC<CourseFaqProps> = ({ course }) => {
  const faqs = course.Faqs || []
  return (
    <section className="course-faq">
      <h3>Frequently Asked Questions</h3>
      <ul>
        {faqs.length > 0 ? (
          faqs.map((faq) => (
            <li key={faq.id}>
              <h4>{faq.title}</h4>
              <p>{faq.description}</p>
              {faq.externalLink && (
                <a href={faq.externalLink} target="_blank" rel="noopener noreferrer">
                  Visit Resource
                </a>
              )}
            </li>
          ))
        ) : (
          <p>No FAQs available.</p>
        )}
      </ul>
    </section>
  );
};

export default CourseFaqs;
