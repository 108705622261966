// src/pages/About.tsx
import React from 'react';
import './About.css'; // Optional: You can create a CSS file for styling
import { Helmet } from 'react-helmet-async';

const About: React.FC = () => {
  return (
    <div className="about">
           <Helmet>
        <title>About Us - Course Echo | Coding Tutorials and Courses</title>
        <meta name="description" content="Explore Course Echo - Your go-to destination for coding tutorials in Java, Angular, Flask, AWS, and more. Learn coding in Hindi and English!" />
        <meta name="keywords" content="Course Echo, coding tutorials, Java, Angular, Flask, AWS, MySQL, PHP, coding in Hindi, coding in English, programming courses, tech tutorials" />
        <meta name="author" content="Course Echo Team" />
        <meta property="og:title" content="About Us - Course Echo | Coding Tutorials and Courses" />
        <meta property="og:description" content="Learn programming with Course Echo - Comprehensive tutorials in Java, Angular, Flask, AWS, and more. Your coding journey starts here!" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://courseecho.com/about" />
        <meta property="og:image" content="https://courseecho.com/logo.png" />
        <meta name="twitter:card" content="https://courseecho.com/logo.png" />
        <meta name="twitter:title" content="About Us - Course Echo | Coding Tutorials and Courses" />
        <meta name="twitter:description" content="Explore Course Echo - Tutorials and courses in Java, Angular, Flask, and more!" />
        <meta name="twitter:image" content="https://courseecho.com/logo.png" />
        <meta name="twitter:creator" content="@CourseEcho" />
     
      </Helmet>

      <h1 className="header">Welcome to Course Echo</h1>

      <p>
        Your go-to destination for comprehensive coding knowledge in Hindi and English! 🚀 
        Dive into the world of programming with our in-depth tutorials and courses covering Java, Angular, JavaScript, Flask, Hostinger, AWS, MySQL, PHP, and more.
      </p>
      <p>
        🖥️ At **Course Echo**, we provide a variety of learning options to suit your needs:
        - **Online Courses**: Learn at your own pace with our structured and engaging online classes.
        - **Offline Classes**: Experience hands-on, in-person training with industry experts.
      </p>
      <p>
        🌟 To deliver the best course content, we're constantly expanding our team and hiring:
        - **Freelance Trainers**: Share your expertise and contribute to building top-notch tutorials.
        - **Remote Trainers**: Work with us from anywhere in the world to empower learners globally.
      </p>
      <p>
        🌐 Whether you're a beginner or an experienced coder, our step-by-step guides and interactive sessions will help you master the art of programming. 
        From building dynamic web applications with Angular to deploying scalable solutions on AWS, we've got you covered.
      </p>
      <p>
        🚀 Join our vibrant community of learners and trainers to stay ahead in the ever-evolving tech landscape. 
        Don't just code; create! Subscribe now for regular updates and elevate your programming skills with **Course Echo**.
      </p>
      <p>
        🚀 Java | Angular | JavaScript | Flask | Hostinger | AWS | MySQL | PHP 🚀
      </p>
      <h2>Follow Us for More Updates!</h2>
      <p>
        #Coding #Programming #WebDevelopment #Java #Angular #JavaScript #Flask #Hostinger #AWS #MySQL #PHP 
        #TechTutorials #FreelanceTrainers #RemoteLearning #courseecho
      </p>
    </div>
  );
};

export default About;
