import  { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    // Inject the Google Tag script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-FSQKK8FK6J';
    document.head.appendChild(script);

    // Initialize Google Tag
    const scriptConfig = document.createElement('script');
    scriptConfig.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-FSQKK8FK6J');
    `;
    document.head.appendChild(scriptConfig);

    return () => {
      // Cleanup scripts if necessary
      document.head.removeChild(script);
      document.head.removeChild(scriptConfig);
    };
  }, []);

  return null;
};

export default GoogleTagManager;
